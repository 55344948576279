import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-radio")
@Component({
    selector: "s25-ng-radio",
    template: `
        @if (isInit) {
            <div class="d-inline-block">
                <input
                    type="radio"
                    id="radio-button-{{ id }}"
                    class="c-label--input"
                    [(ngModel)]="modelValue"
                    (ngModelChange)="onChange($event)"
                    name="{{ name }}"
                    [value]="value"
                    [disabled]="disabled"
                    [attr.aria-label]="ariaLabel"
                />
                <label for="radio-button-{{ id }}" class="c-label--radio">
                    <div class="fake"></div>
                    <ng-content></ng-content>
                </label>
            </div>
        }
    `,
    styles: `
        .c-label--radio {
            align-items: center;
            display: inline-flex;
            padding: 0;
            position: relative;
            cursor: pointer;
        }

        .fake {
            width: 1.25em;
            height: 1.25em;
            margin-right: 0.5em;
        }

        .c-label--radio .fake::before {
            height: 1.25em;
            width: 1.25em;
            transition:
                background-color 0.2s,
                border-color 0.2s;
            align-self: center;
            background-color: #fafaf9;
            border: 1px solid rgba(0, 0, 0, 0.25);
            content: "";
            display: inline-block;
            flex-shrink: 0;
            border-radius: 50%;
            position: absolute;
        }

        ::ng-deep .nm-party--on .c-label--radio .fake::before {
            background-color: #28272c;
            border-color: #3f627f;
        }

        .c-label--radio:hover .fake::before {
            border-color: rgba(0, 0, 0, 0.3);
        }

        ::ng-deep .nm-party--on s25-ng-radio .c-label--radio:hover .fake::before {
            border-color: #73b0e2;
        }

        .c-label--input:focus + .c-label--radio .fake::before {
            background-color: #fff;
            border-color: #2574a9;
            box-shadow: 0 0 0 1px #2574a9;
            z-index: 3;
        }

        ::ng-deep .nm-party--on s25-ng-radio .c-label--input:focus + .c-label--radio .fake::before {
            background-color: #28272c !important;
            border-color: #3f627f !important;
            box-shadow:
                0 0 0 2px #1b2836,
                0 0 0 4px #73b0e2 !important;
        }

        .c-label--radio .fake::after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSI+Cgo8Y2lyY2xlIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgcj0iNSIgZmlsbD0iIzI1NjRhOSIgZmlsbC1ydWxlPSJub256ZXJvIiBjeD0iMTAiIGN5PSIxMCIvPgoKPGNpcmNsZSB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHI9IjkiIGZpbGw9InRyYW5zcGFyZW50IiBjeD0iMTAiIGN5PSIxMCIgc3Ryb2tlPSIjMjU2NGE5Ii8+CgoKPC9zdmc+);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 1.25em 1.25em;
            height: 1.25em;
            opacity: 0;
            width: 1.25em;
            left: 0;
            border: 1px solid transparent;
            content: "";
            flex-shrink: 0;
            display: inline-block;
            /*position: absolute;*/
            z-index: 4;
            align-self: center;
        }

        ::ng-deep .nm-party--on .c-label--radio .fake::after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSI+Cgo8Y2lyY2xlIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgcj0iNSIgZmlsbD0iIzQ5OThkOSIgZmlsbC1ydWxlPSJub256ZXJvIiBjeD0iMTAiIGN5PSIxMCIvPgoKPGNpcmNsZSB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHI9IjkiIGZpbGw9InRyYW5zcGFyZW50IiBjeD0iMTAiIGN5PSIxMCIgc3Ryb2tlPSIjNDk5OGQ5Ii8+CgoKPC9zdmc+);
        }

        .c-label--input:checked + .c-label--radio .fake::after {
            border: 0;
            transition:
                transform 0.2s ease,
                opacity 0.2s ease,
                -webkit-transform 0.2s ease;
            opacity: 1;
            transform: none;
        }

        .c-label--input:checked + .c-label--radio .fake::before {
            opacity: 0;
        }
    `,
})
export class S25RadioComponent<T> implements OnInit {
    @Input() modelValue: T;
    @Input() disabled?: boolean = false;
    @Input() value: T;
    @Input() ariaLabel = "";
    @Input() name: string;

    @Output() modelValueChange = new EventEmitter<T>();

    isInit = false;
    id: number;
    static count = 0;

    constructor(private changeDetector: ChangeDetectorRef) {
        S25RadioComponent.count++;
        this.id = S25RadioComponent.count;
    }

    ngOnInit() {
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    onChange(val: T) {
        this.modelValueChange.emit(val);
    }
}
